function number_with_delimiter(num) {
  if (num) {
    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  } else {
    return ''
  }
}

export default {
  data: () => ({
    is_focus: false,
    input_number: null,
    input_text: null,
  }),
  mounted: function () {
    this.input_number = this.$el.dataset.value
    this.input_text = number_with_delimiter(this.input_number);
  },
  methods: {
    onInputText: function (event) {
      this.input_number = this.input_number.replace(/\D/g, '')
    },
    onFocusInputText: function (event) {
      this.is_focus = true
    },
    onClickInputText: function (event) {
      this.is_focus = true
    },
    onBlurInputNumber: function (event) {
      this.is_focus = false
      this.input_number = this.input_number === '' ? '' : Number(this.input_number);
      this.input_text = number_with_delimiter(this.input_number);
    }
  }
}
