// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

// import './application/foundation/common';
// import './application/foundation/validator';
// import './application/foundation/chatbox';
// import './application/foundation/dropify';
// import './application/lib/cocoon';

import toastr from 'toastr'
toastr.options = {
  "positionClass": "toast-bottom-center",
  "closeButton": true,
  "timeOut": 1000 * 5,
  "extendedTimeOut": 1000 * 5,
  "showDuration": 300,
  "hideDuration": 300,
}
window.toastr = toastr

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
// resizeイベントの取得
window.addEventListener('resize', () => {
  // あとは上記と同じスクリプトを実行
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


// see: https://blog.rhyztech.net/how_to_fusion_rails_and_vue/
import Vue from 'vue/dist/vue.esm'
Vue.directive('init', {
  bind: function (el, binding, vnode) {
    vnode.context[binding.arg] = binding.value
  }
});
var vms = [];
var options = {};
var requireContext = require.context('./application/vue', false, /\.js$/)
requireContext.keys().forEach(key => {
  let name = key.split('/').pop().split('.').shift()
  options[name] = requireContext(key).default
});
document.addEventListener('DOMContentLoaded', () => {
  let templates1 = document.querySelectorAll('[data-vue]')
  for (let el of templates1) {
    if (options[el.dataset.vue]) {
      let vm = new Vue(Object.assign(options[el.dataset.vue], { el }))
      vms.push(vm)
    } else {
      console.error('Vue component does not exist:', el.dataset.vue);
    }
  }

  let templates2 = document.querySelectorAll('[data-vue-template]');
  for (let el of templates2) {
    let vm = new Vue({ el });
    vms.push(vm);
  }
});


$(function() {
  var $select2 = $('.js-select2')
  var $spinner = $('.js-loading-spinner--university_id')
  $select2.select2({
    language: "ja", //日本語化
  }).on('select2:open', function (e) {
    $spinner.data('open', 'true')
    $spinner.removeClass('active');
    console.log("open");
  })

  $('.js-select2-overlay').on('click', function(e) {
    if ($spinner.data('open') == 'true') {
      $spinner.removeClass('active');
      setTimeout(() => {
        $select2.select2("close");
        $spinner.data('open', 'false')
      }, 1);
    } else {
      $spinner.addClass('active');
      setTimeout(() => {
        $select2.select2("open");
        $spinner.data('open', 'true')
      }, 10);
    }
  })
})

// this is globacl unique function name
window.d75825a3 = function(element) {
  if (element.value == 0) {
    element.style.color = '';
  } else {
    element.style.color = 'black';
  }
}
$(function() {
  $('.js-d75825a3').each(function() {
    if ($('.js-d75825a3').first().val() == '') {
      $(this).get(0).style.color = '';
    } else {
      $(this).get(0).style.color = 'black'; 
    }
  })
})

$(function () {
  $('.js-sorder_on_submit').on('click', function (e) {
    // e.preventDefault()
    const sort_by = $(this).data('sortby')
    const $form = $(this).parents('form')
    const $q_sort_by = $form.find('#q_sort_by')
    $q_sort_by.val(sort_by)
  })
})

$(function() {
  $('.js-smooth').on('click', function() {
    const speed = 500;
    const href = $(this).attr("href").replace('/', '');
    if ($(href)) {
      const position = $(href).offset().top;
      $('html').animate({scrollTop:position}, speed, 'swing');
      return false;
    }
  })
})

$(function () {
  var $selectArea = $('.js-select-area')
  $selectArea.find('input[type="checkbox"]').on('change', function (e) {
    var root_id = $(this).data('root')
    var checkbox_type = $(this).data('type')
    if (checkbox_type == 'root') {
      var $root = $(this)
      if ($root.prop('checked')) {
        $selectArea.find(`input[type="checkbox"][data-root="${root_id}"][data-type="child"]`).prop('checked', true)
      } else {
        $selectArea.find(`input[type="checkbox"][data-root="${root_id}"][data-type="child"]`).prop('checked', false)
      }
    } else if (checkbox_type == 'child') {
      var $child = $(this)
      if ($child.prop('checked')) {
        if ($selectArea.find(`input[type="checkbox"][data-root="${root_id}"][data-type="child"]:not(:checked)`).length == 0) {
          $selectArea.find(`input[type="checkbox"][data-root="${root_id}"][data-type="root"]`).prop('checked', true)
        }
      } else {
        $selectArea.find(`input[type="checkbox"][data-root="${root_id}"][data-type="root"]`).prop('checked', false)
      }
    }
  })
})

$(function () {
  $('.js-counter').each(function () {
    const $couter = $(this)
    function injectCount() {
      const count = $couter.find(`input[type="checkbox"]:checked`).length
      if (count == 0) {
        $couter.find('.js-counter-number').html(``)
      } else {
        $couter.find('.js-counter-number').html(`(${count})`)
      }
    }
    $couter.find('.js-counter-checkboxes').on('change', function () {
      injectCount()
    })
    injectCount()
  })
})


$(function () {
  // スマホでの拡大中止
  //https://webinlet.com/2020/ios11%E4%BB%A5%E9%99%8D%E3%81%A7%E3%83%94%E3%83%B3%E3%83%81%E3%82%A4%E3%83%B3%E3%82%A2%E3%82%A6%E3%83%88%E6%8B%A1%E5%A4%A7%E7%B8%AE%E5%B0%8F%E7%A6%81%E6%AD%A2
  document.body.addEventListener("touchstart", function(e){
    if (e.touches && e.touches.length > 1) {
    e.preventDefault();
    }
  }, {passive: false});
  document.body.addEventListener("touchmove", function(e){
    if (e.touches && e.touches.length > 1) {
    e.preventDefault();
    }
  }, {passive: false});
})


$(function() {
  if ($('.js-sort-navi').length) {

    var isSearchHeadClicked = false;
    var sortNaviContent = $('.js-sort-navi');
    sortNaviContent.on('click', function (event) {
      event.stopPropagation();
    });

    $('.js-nav').on('click', function (event) {
      event.stopPropagation();

      if (isSearchHeadClicked == true) {
        return false;
      }
      isSearchHeadClicked = true;

      if ($(this).children('.js-sort-navi-head').hasClass('active')) {
        $(this).children('.js-sort-navi-head').removeClass('active');
        $(this).children('.js-sort-navi-head').next(sortNaviContent)
          .slideUp(300, function () { isSearchHeadClicked = false; });
      } else {
        $('.js-sort-navi-head').removeClass('active');
        $('.js-sort-navi-head').next(sortNaviContent).slideUp(300);
        $(this).children('.js-sort-navi-head').addClass('active');
        $(this).children('.js-sort-navi-head').next(sortNaviContent)
          .slideDown(300, function () { isSearchHeadClicked = false; });
      }
    });
  };
})


$(function() {
  const setSpinner = (el) => {
    if (!el) return null;
    el.addEventListener('click', (e) => {

      // firefox対策
      setTimeout(function () {
        el.classList.add('is-loading');
        $(el).html(`<span class="spinner" style="margin: 0;">
            <img src="/img/common/spinner.svg" alt="" width="32" height="32" />
          </span>`);
      }, 100);

    });
  };
  const buttons = document.querySelectorAll('.js-spinner-loading-btn');
  buttons.forEach((button) => setSpinner(button));
});
